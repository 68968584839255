body, html {
    margin: 0;
    padding: 0;
    background-color: white;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    display: none; 
}

/* NAV */

 /* Clase para ocultar el splash */
 .hiddensplash {
    opacity: 0;
} 


/*   NAV INICIO   */
.logo-nav {
    width: 5vw;
    height: auto;
}

.navbar {
    padding: 1% 2% 1% 2%;
    transition: opacity 1s ease; 
    opacity: 1;
}

.navbar-visible {
    opacity: 1;
}

.icon-nav {
    height: auto; 
    margin: 0 0.5vw; 

}


.icon-nav:nth-child(1) {
    width: 0.5vw; 
}

.icon-nav:nth-child(2) {
    width: 1vw; 
}

.icon-nav:nth-child(3) {
    width: 1vw; 
}

.icon-nav:nth-child(4) {
    width: 1vw; 
}

.links-nav {
    text-decoration: none !important;
    color: #333;
    transition: color 0.3s ease; 
    font-family: 'Fira Sans Condensed', sans-serif;
    font-size: 1vw;
    margin-right: 4vw;
}

.links-nav:hover {
    color: #EDAC9F; 
}

.links-nav:last-child {
    margin-right: 0; 
}


/*   BANNER INICIO   */

.banner-section {
    position: relative;
    width: 100vw;
    height: 35vw; 
    margin-top: 5vw;
    overflow: hidden;
}

.banner-content {
    position: relative;
    width: 100%;
    height: 100%;
}

/* Video de fondo */
.video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -15VW;
    margin-top: -10VW;
    width: 135vw; /* Ocupa todo el ancho */
    height: 125vh; /* Ocupa todo el alto */
    z-index: 1; /* Envía el video al fondo */
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 135vw; /* Ocupa todo el ancho */
    height: 125vh; /* Ocupa todo el alto */ 
    object-fit: cover; /* Recorta el video para cubrir todo el espacio sin bordes */
    pointer-events: none; /* Desactiva la interacción con el video */
    border: none; /* Sin bordes */
}

.overlay-content {
    position: absolute;
    top: 40%;
    margin-right: 5vw;
    right: 0; 
    transform: translateY(-50%); 
    text-align: center; 
    color: white;
    z-index: 2;
    padding-right: 1vw; 
}


.bordes-pruebas{
    border-width: 1px;
    border-style: solid;
    border-color: blue;
}

.legacy-text {
    font-size: 1.6vw;
    margin-bottom: .9vw;
    font-family: 'Fira Sans Condensed';
    font-weight: 300;
    line-height: 0.5; 

}

.banner-svg {
    margin: .3vw 0;
    width: 25vw;
    height: auto;
}

.banner-title {
    font-size: 2.5vw;
    font-family: 'Yanone Kaffeesatz';
    
    margin-top: 2vw;
}


.bold-text {
    font-weight: bold; /* "MUJEREAL" */
}

.regular-text {
    font-weight: 300; /* "TEQUILA" */
}

.cta-button {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color: white;
    font-family: 'Fira Sans Condensed';
    border: none;
    font-weight: 300;
    padding: .2vw 4.5vw;
    cursor: pointer;
    font-size: 1.1vw;
}

.cta-button:hover {
    background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
    color:#d2d2d2;
    opacity: 80%;
    transition: color 0.3s ease, background 0.3s ease;
}


.floating-button {
    position: fixed;
    bottom: 2vw;
    right: 4vw;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.floating-button .cta-button {
    padding: .5vw 3vw;
    font-size: 1.2vw; /* Ajusta el tamaño del botón flotante */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega un poco de sombra para que destaque */
}

.floating-button .cta-button:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Cambia la sombra al pasar el mouse */
}


/*   STORY   */

.mujereal-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7vw 0;
}

.mujereal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}

.image-container {
    flex: 1;
}

.image-container img {
    width: 95%;
    height: auto;
}

.text-container {
    flex: 1;
    padding-left: .1vw;
}

.text-container h2 {
    font-family: 'Yanone Kaffeesatz';
    font-size: 1.8vw;
    font-weight: 400;
    color: #333;
    margin-bottom: .9vw;
}

.text-container h3 {
    font-family: 'Fira Sans Condensed';
    font-size: 1.1vw;
    color: #333;
    font-weight: 400;
    margin-bottom: 1vw;
}

.text-container p {
    font-family: 'Roboto';
    font-size: 1vw;
    color: #4d4d4d;
    margin-bottom: 2vw;
    width: 30vw;
    font-weight: 300;
}

.cta-button2 {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color: white;
    font-family: 'Fira Sans Condensed';
    border: none;
    font-weight: 300;
    padding: .3vw 2vw;
    cursor: pointer;
    font-size: .9vw;
}

.cta-button2:hover {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color:#d2d2d2;
    opacity: 80%;
    transition: color 0.3s ease, background 0.3s ease; 
}


.divider {
    margin-top: 5vw;
    text-align: center;
}

.divider img {
    width: 30vw;
    height: auto;
}


/*   PRODUCTS   */

.products-section {
    padding: 4vw 0;
    background-color: #fdf7f6;
    text-align: center;
}

.products-title {
    font-family: 'Yanone Kaffeesatz';
    font-size: 3.5vw;
    color: #333;
    font-weight: 300;
    margin-bottom: 2vw;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2vw;
    max-width: 70%;
    margin: 0 auto;
}

.product-item {
    position: relative;
    text-align: center;
}

.product-image {
    position: relative;
    width: 100%;
}

.product-image img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(237, 172, 159, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1vw;
}

.product-name {
    font-family: 'Yanone Kaffeesatz';
    font-size: 2.5vw;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
    text-decoration: none;
}

.more-info-button {
    background: #4d4d4d;
    color: white;
    font-family: 'Fira Sans Condensed';
    border: none;
    font-weight: 300;
    padding: 0.5vw 2vw;
    cursor: pointer;
    font-size: 1vw;
    transition: background 0.3s ease, color 0.3s ease;
}

.product-item:hover .overlay,
.product-item:hover .product-name,
.product-item:hover .more-info-button {
    opacity: 1;
    color: #edac9f;
    transition: color 0.3s ease, background 0.3s ease; 
    
}

.product-item:hover .more-info-button {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color: #fff;
    transition: color 0.3s ease, background 0.3s ease; 
}


/*   VIDEO COMPONENTE   */

.video-section {
    position: relative;
    width: 100%;
    height: 35vw; 
    overflow: hidden;
}

.video-section2 {
    position: relative;
    width: 100%;
    height: 35vw; 
    margin-top: 5VW;
    margin-bottom: 5vw;
    overflow: hidden;
}

.video-section3 {
    position: relative;
    width: 100%;
    height: 35vw; 
    margin-top: 5VW;
    margin-bottom: 5vw;
    overflow: hidden;
}

.video-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

/* Video de fondo */
.video-wrapper2 {
    position: absolute;
    top: -10;
    left: 0;
    margin-left: -15VW;
    margin-top: -10VW;
    width: 135vw; /* Ocupa todo el ancho */
    height: 125vh; /* Ocupa todo el alto */
    z-index: 1; /* Envía el video al fondo */
}

.video-wrapper2 iframe {
    position: absolute;
    top: -10;
    left: 0;
    width: 135vw; /* Ocupa todo el ancho */
    height: 125vh; /* Ocupa todo el alto */ 
    object-fit: cover; /* Recorta el video para cubrir todo el espacio sin bordes */
    pointer-events: none; /* Desactiva la interacción con el video */
    border: none; /* Sin bordes */
}

/* Video de fondo */
.video-wrapper3 {
    position: absolute;
    top: -10;
    left: 0;
    margin-left: -15VW;
    margin-top: -10VW;
    width: 135vw; /* Ocupa todo el ancho */
    height: 125vh; /* Ocupa todo el alto */
    z-index: 1; /* Envía el video al fondo */
}

.video-wrapper3 iframe {
    position: absolute;
    top: -10;
    left: 0;
    width: 135vw; /* Ocupa todo el ancho */
    height: 125vh; /* Ocupa todo el alto */ 
    object-fit: cover; /* Recorta el video para cubrir todo el espacio sin bordes */
    pointer-events: none; /* Desactiva la interacción con el video */
    border: none; /* Sin bordes */
}

.overlay-video-content {
    position: absolute;
    top: 40%;
    text-align: center; 
    justify-content: center;
    color: white;
    z-index: 2;
}

.video-title {
    font-size: 2.5vw;
    font-family: 'Yanone Kaffeesatz';
    
    margin-top: 2vw;
}


.bold-text {
    font-weight: bold; /* "MUJEREAL" */
}

.regular-text {
    font-weight: 300; /* "TEQUILA" */
}

.video-button {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d); 
    color: white;
    font-family: 'Fira Sans Condensed';
    border: none;
    font-weight: 300;
    padding: .2vw 3.5vw;
    cursor: pointer;
    font-size: 1.1vw;
}

.video-button:hover {
    background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
    color:#d2d2d2;
    opacity: 90%;
    transition: color 0.3s ease, background 0.3s ease; 
}

/*  CONTACTO  */


.contact-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdf7f6;
    margin-top: 6vw;
    margin-bottom: 2vw;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
   
}

.contact-image {
  margin-right: 2vw;
  
}

.contact-image img {
    width: 40vw;
    height: auto;
  
}

.contact-form {
    flex: 1;
    
}

.contact-form h2 {
    font-family: 'Yanone Kaffeesatz';
    font-size: 1.3vw;
    font-weight: 400;
    color: #4d4d4d;
    margin-bottom: -.5VW;
}

.contact-title {
    font-weight: 500;
    font-size: 3vw;
    font-family: 'Yanone Kaffeesatz';
    margin-bottom: -.5VW;
}

.contact-subtitle {
    font-family: 'Roboto';
    font-size: 1vw;
    color: #4d4d4d;
    font-weight: 500;
    
}

.divider{
    justify-content: left;
    text-align: left;
    margin-top: 2.5vw;
}

.divider img {
    width: 25vw;
    margin-bottom: 1.5vw;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form input,
.contact-form textarea {
    font-family: 'Roboto';
    font-size: 1vw;
    color: #999999;
    padding: 0.3vw;
    margin-bottom: 1vw;
    border: 1px solid #ccc;
    background-color: #fdf7f6;
    font-weight: 300;
    width: 57vw;
}

.contact-form textarea {
    resize: vertical;
    min-height: 8vw;
}

.contact-button {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color: white;
    font-family: 'Fira Sans Condensed';
    border: none;
    font-weight: 300;
    padding: 0.1vw 1vw;
    width: 18vw;
    cursor: pointer;
    font-size: 1.3vw;
    transition: background 0.3s ease;
}

.contact-button:hover {
    background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
    color: #d2d2d2;
    opacity: 70%;
    transition: color 0.3s ease, background 0.3s ease; 
}

.contact-req{
    font-family: 'Roboto';
    font-size: 1vw;
    color: #999999;
    margin-top: 3vw;
}

/* STORY SECTION  */


.story-section {
    padding: 1vw 0;
    text-align: center;
    background-color: #fff;
}

.story-container {
    width: 70%;
    margin: 0 auto;
}

.story-title {
    font-family: 'Yanone Kaffeesatz';
    font-size: 2vw;
    font-weight: 300;
    color: #333;
    margin-bottom: 0.5vw;
}

.story-subtitle {
    font-family: 'Yanone Kaffeesatz';
    font-size: 3vw;
    font-weight: 400;
    color: #333;
    margin-bottom: 2vw;
}

.story-content {
    text-align: center;
}

.story-image {
    margin: 0 auto;
}

.story-image img {
    width: 100%;
    height: auto;
}

.story-text {
    margin-top: 2vw;
    
}

.story-text h4 {
    font-family: 'Fira Sans Condensed';
    font-size: 1.5vw;
    font-weight: 400;
    color: #333333;
    text-align: left;
    margin-bottom: 1vw;
}

.story-text p {
    font-family: 'Roboto';
    font-size: .98vw;
    color: #4d4d4d;
    font-weight: 300;
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 2vw;
}

.story-divider {
    margin-top: 2vw;
    text-align: center;
}

.story-divider img {
    width: 25vw;
    height: auto;
    margin-top: 2vw;
}


/* STORY 2 */

.heritage-section {
    padding: 5vw 0;
    background-color: #fff;
    text-align: center;
}

.heritage-container {
    width: 80%;
    margin: 0 auto;
}

.heritage-block {
    display: flex;
    align-items: center;
    margin-bottom: 3vw;
}

.heritage-image {
    flex: 1;
    padding: 1vw;
    text-align: center;
}

.heritage-image img {
    width: 100%;
}

.heritage-content {
    flex: 1;
    padding: 1vw;
    text-align: justify;
}

.heritage-content h4 {
    font-family: 'Yanone Kaffeesatz';
    font-size: 1.5vw;
    font-weight: 400;
    color: #333;
    margin-bottom: 1vw;
}

.heritage-content p {
    font-family: 'Roboto';
    font-size: 1vw;
    color: #4d4d4d;
    font-weight: 300;
    line-height: 1.6;
}

.heritage-divider {
    margin: 2vw 0;
    text-align: center;
}

.heritage-divider img {
    width: 25vw;
    height: auto;
}

/* FOOTER */


.footer {
    text-align: center;
    padding: 1vw;
    font-family: 'Fira Sans Condensed';
}

.footer-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15vw;
    font-size: 1.2vw;
    color: #edac9f;
}

.footer-contact .fas {
    margin-right: 5px;
}

.footer-socials a {
    margin: 0 8px;
    color: #edac9f;
}

.footer-socials a:hover {
    color: transparent;
    background: linear-gradient(90deg, #ab735e, #ffb6a1, #ab735e); 
    background-size: 200% 200%; 
    -webkit-background-clip: text; 
    background-clip: text;
    display: inline-block; 
    -webkit-background-clip: text; 
    background-clip: text;
    transition: color 0.3s ease, background 0.3s ease;
}

.footer-divider {
    margin: 20px 0;
}

.footer-divider img {
    width: 60vw;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1vw;
   
    border-top: 1vw ;
    padding-top: 10px;
   
}

.footer-arrow{
    justify-content: left;
    margin-left: 0;
    z-index: 9999;
}

.footer-arrow img {
    width: 2.2vw;
    transition: filter 0.3s ease; /* Transición suave al aplicar filtro */
    z-index: 9999;
}


.footer-arrow:hover img {
    filter: grayscale(100%); 
}


.footer-arrow a:hover {
    text-decoration: underline;  
    color: #edac9f;  
}

.footer-contact a:hover {
    color: #4d4d4d; 
    transition: color 0.3s ease, background 0.3s ease; 
}

.footer-bottom p{
margin-left: 8vw;
color: #b2b2b2;
text-decoration: none;
font-family: 'Roboto';
font-size: .8vw;
}

.footer-contact a{
    text-decoration: none;
    color: #edac9f ;
}

.footer-developed a{
text-decoration: none;
}
.footer-bottom a{
    font-size: .8vw;
    color: #b2b2b2;
    text-decoration: none;
    font-family: 'Roboto';
    }

.footer-bottom div {
    justify-content: right;
}

.footer-developed img {
    margin-right: 2vw;
    width: 7vw;
}

/* PRODUCTS SECTION */

.mujereal-section2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdf7f6;
    padding-bottom: 0;
}

.mujereal-container2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 7vw;
}

.image-container2 {
    flex: 1;
}

.image-container2 img {
    width: 95%;
    height: auto;
}

.text-container2 {
    flex: 1;
    padding-left: .1vw;
    margin-top: 1vw;
}

.text-container2 h2 {
    font-family: 'Yanone Kaffeesatz';
    font-size: 3.5vw;
    font-weight: 500;
    color: #333;
    margin-bottom: .1vw;
}
.text-container2 h4 {
    font-family: 'Yanone Kaffeesatz';
    font-size: 1.4vw;
    font-weight: 300;
    color: #333;
    margin-bottom: .1vw;
}

.text-container2 h3 {
    font-family: 'Roboto';
    font-size: 1.05vw;
    color: #4d4d4d;
    font-weight: 600;
    margin-bottom: 1vw;

}

.text-container2 strong{
    font-size: 1.3vw;
    font-family: 'Yanone Kaffeesatz';
    font-weight: 400;
}

.text-container2 p {
    font-family: 'Roboto';
    font-size: .98vw;
    color: #4d4d4d;
    margin-bottom: 2vw;
    width: 55vw;
    text-align: justify;
    font-weight: 300;
}

.detail {
    margin-top: 2vw;
}

.detail-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5vw;
}

.detail-icon {
    width: 2.3vw;
    margin-right: 1.5vw;
}

.detail-icon2 {
    width: 1.7vw;
    margin-right: 2vw;
}


.detail p {
    font-family: 'Roboto';
    font-size: 1vw;
    color: #4d4d4d;
    font-weight: 300;
    margin: 0;
}

.cta-button3 {
    background-image: url('/public/img/btn.svg'); 
    color: white;
    font-family: 'Fira Sans Condensed';
    border: none;
    font-weight: 300;
    padding: .2vw 5vw;
    cursor: pointer;
    font-size: 1.1vw;
    margin-top: 3vw;
    transition: color 0.3s ease, background 0.3s ease;
}

.cta-button3:hover {
    background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
    color: #d2d2d2;
    opacity: 80%;
    transition: color 0.3s ease, background 0.3s ease;
}

.heritage-divider2 {
    margin: 2vw 0;
    text-align: left;
}

.heritage-divider2 img {
    width: 25vw;
    height: auto;
}

/* SPLASH */

.splash-section {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("/public/img/splash.png") no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;
    backdrop-filter: blur(5px);
}


.splash-content {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2vw 1vw;
    border-radius: 15px;
    text-align: center;
    width: 23vw;
}

.splash-img {
    width: 40%;
    margin: 0 auto 1vw auto;
}

.splash-text {
    font-family: 'Roboto';
    font-size: 1.1vw;
    color: #808080;
    margin: 1vw 0;
}

.splash-button,
.splash-button-alt {
    width: 100%;
    padding: 0.5vw;
    margin: .5vw 0;
    border: none;
    font-size: 1.1vw;
    font-family: 'Fira Sans Condensed';
    cursor: pointer;
}

.splash-button {
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color: white;
}

.splash-button:hover {
    background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
    color: #d2d2d2;
}

.splash-button-alt {
    background-color: #f5f5f5;
    color: #a9715c;
}

.splash-footer {
    position: absolute;
    bottom: 2vw;
    color: white;
    font-size: 1vw;
    font-family: 'Roboto';
}

.divider2 {
    margin-top: 2vw;
    text-align: center;
    
}

.divider2 img {
  width: 50vw;
    
}

/* INSTAGRAM */

.instagram-section {
    padding: 2vw 0;
    text-align: center;
    background-color: #fff;
    
}

.instagram-title {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra el contenido horizontalmente */
    gap: 0.5vw; 
    
}


.instagram-title h2 {
    font-family: 'Fira Sans Condensed';
    font-size: 1vw;
    color: #edac9f;
    font-weight: 400;
    margin-bottom: 3.5vw;
    margin-top: 1vw;
}

.instagram-icon {
    width: .9vw; 
    margin-bottom: 3.5vw;
    margin-top: 1vw;

}

.instagram-container {
    display: flex;
    justify-content: center;
    gap: 0vw;
    margin-bottom: 1.5vw;
}

.instagram-post {
    flex: 1;
    width: 25vw; /* Establece un tamaño fijo para las publicaciones */
    height: 30vw;
}

.instagram-post img,
.instagram-post video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Mantiene la relación de aspecto y llena el contenedor */
    object-position: top;
}

.instagram-post:hover img,
.instagram-post:hover video {
    transform: scale(1.05);
}



/* Custom button styles for SweetAlert */
.btn-custom {
    background-color: #a76c5d; /* Light pink color */
    color: white;
    border: 2px solid white; /* Borde blanco */
    box-shadow: none; /* Eliminar cualquier sombra */
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Montserrat';
}

.btn-custom:hover {
    background-color: #a76c5d; /* Sin cambio para hover */
    border: 2px solid white; /* Asegurar el borde blanco también en hover */
    box-shadow: none; /* Asegurar que no haya sombra en hover */
    font-family: 'Montserrat';
}

.btn-custom-error {
    background-color: #a76c5d; /* Light red for error */
    color: white;
    border: 2px solid white; /* Borde blanco */
    box-shadow: none; /* Eliminar sombras */
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Montserrat';
}

.btn-custom-error:hover {
    background-color: #a76c5d; /* Sin cambio para hover */
    border: 2px solid white; /* Asegurar el borde blanco también en hover */
    box-shadow: none; /* Asegurar que no haya sombra en hover */
    
}




@media only screen and (max-width: 768px) {
    body, html {
        margin: 0;
        padding: 0;
    }

    .navbar .container-fluid {
     flex-direction: row; 
     align-items: center; 
 }

 .navbar {
     padding-top: 2.5vw;
     padding-bottom: 2.5vw;
 }


 .logo-nav {
     width: 13vw; 
     margin-right: 40vw;
 }

 .icons-m {
     position: absolute;
     top: 10px; 
     right: 10px;
     display: flex;
     flex-direction: column; 
     align-items: flex-end; 
     z-index: 10;
 }
 
 .icon-nav {
     margin-bottom: 3vw; 
     text-align: right;
     width: 4vw;
     cursor: pointer;
 }
 

 .icon-nav:nth-child(1) { width: 2.7vw; }
 .icon-nav:nth-child(2) { width: 5vw; }
 .icon-nav:nth-child(3) { width: 5vw; }
 .icon-nav:nth-child(4) { width: 4.5vw; }
 
 .menu-icon {
     font-size: 2rem; 
     color: #EDAC9F; 
     margin-right: auto; 
     border-radius: 2vw;
     border: 2px solid #EDAC9F;
 }
 
 .offcanvas {
     width: 60% !important;
 }
 
 .links-nav {
     font-size: 4vw;
     font-family: 'Fira Sans Condensed', sans-serif;
 }

 .list-unstyled{
    margin-top: 2vw;
 }

 .offcanvas-body {
    margin-top: 6vw;
 }

 .offcanvas-body div{
     margin-top: 4vw;
 }

 .links-nav:hover {
     color: #EDAC9F;
 }

 /* SPLASH MOV */

 .splash-content {
     width: 80%;
     padding: 10vw 5vw;
 }

 .splash-img {
     width: 50%;
 }

 .splash-text {
     font-size: 4vw;
     margin-bottom: 4vw;
 }

 .splash-button,
 .splash-button-alt {
     font-size: 4vw;
     padding: 3vw 0;
 }

 .splash-footer {
     font-size: 3vw;
 }

 .divider2 {
     margin-top: 10vw;
     text-align: center;
     
 }
 
 .divider2 img {
   width: 90vw;
     
 }

 /* BANNER INICIO */

 .banner-section {
     position: relative;
     width: 100%;
     height: 100vh; 
     margin-top: 0; 
     overflow: hidden;
 }
 
 .banner-content {
     position: relative;
     width: 100%;
     height: 100%;
 }

 .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -10vw;
    margin-top: -5.5vw;
    width: 110vw; /* Abarca el ancho completo de la pantalla */
    height: 40vh; /* Abarca la altura completa de la pantalla */
    z-index: 1; /* Envía el video al fondo */
    overflow: hidden; /* Oculta cualquier desbordamiento */
}

.video-wrapper iframe {
    position: absolute;
    top: -50;
    left: 0;
    margin-top: 2vw;
    width: 110vw; /* Abarca todo el ancho de la pantalla */
    height: 50vh; /* Abarca toda la altura de la pantalla */
    object-fit: cover; /* Cubre todo el espacio sin deformar */
    pointer-events: none; /* Desactiva la interacción con el video */
    border: none; /* Sin bordes */
}
 
 .overlay-content {
     position: absolute;
     top: 55%;
     left: 50%;
     transform: translate(-50%, -50%);
     text-align: center;
     color: black;
     z-index: 2;
     width: 80%;
     padding: 0 5vw;
 }

 .legacy-text {
     font-size: 7.5vw; 
     margin-bottom: 2vw;
     font-family: 'Fira Sans Condensed';
     font-weight: 300;
     line-height: 1; 
 }

 .banner-svg {
    margin: 2vw 0;
    width: 70vw;
    height: auto;
    filter: invert(1);  /* Invertir colores (blanco a negro, negro a blanco) */
  }

 .banner-title {
     font-size: 9.5vw; 
     font-family: 'Yanone Kaffeesatz';
     margin-top: 3vw;
 }

 .bold-text {
     font-weight: bold;
 }

 .regular-text {
     font-weight: 300;
 }

 .cta-button {
     background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
     color: white;
     font-family: 'Fira Sans Condensed';
     border: none;
     font-weight: 300;
     padding: 3vw 10vw;
     cursor: pointer;
     font-size: 5vw; 
     margin-top: 3vw;
     transition: color 0.3s ease, background 0.3s ease;
 }

 .cta-button:hover {
     background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
     color: #d2d2d2;
     opacity: 80%;
     transition: color 0.3s ease, background 0.3s ease;
 }

 /*   VIDEO COMPONENTE   */

 .video-section {
     position: relative;
     width: 100%;
     height: 100vh; 
     margin-top: 0; 
     overflow: hidden;
 }

 .video-section2 {
     position: relative;
     width: 100%;
     height: 100vh;
     margin-top: 0; 
     overflow: hidden;
 }
 
 .video-content2 {
     position: relative;
     width: 100%;
     height: 100%;
 }

 .video-section3 {
    position: relative;
    width: 100%;
    height: 100vh;
    margin-top: 0; 
    overflow: hidden;
}

.video-content3 {
    position: relative;
    width: 100%;
    height: 100%;
}
 .video-content {
     position: relative;
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: center;
     
 }

 .video-wrapper2 {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -10vw;
    margin-top: -26vw;
    width: 110vw; /* Abarca el ancho completo de la pantalla */
    height: 40vh; /* Abarca la altura completa de la pantalla */
    z-index: 1; /* Envía el video al fondo */
    overflow: hidden; /* Oculta cualquier desbordamiento */
}

.video-wrapper2 iframe {
    position: absolute;
    top: -50;
    left: 0;
    margin-top: 2vw;
    width: 110vw; /* Abarca todo el ancho de la pantalla */
    height: 50vh; /* Abarca toda la altura de la pantalla */
    object-fit: cover; /* Cubre todo el espacio sin deformar */
    pointer-events: none; /* Desactiva la interacción con el video */
    border: none; /* Sin bordes */
}

.video-wrapper3 {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -10vw;
    margin-top: -7vw;
    width: 110vw; /* Abarca el ancho completo de la pantalla */
    height: 40vh; /* Abarca la altura completa de la pantalla */
    z-index: 1; /* Envía el video al fondo */
    overflow: hidden; /* Oculta cualquier desbordamiento */
}

.video-wrapper3 iframe {
    position: absolute;
    top: -50;
    left: 0;
    margin-top: 2vw;
    width: 110vw; /* Abarca todo el ancho de la pantalla */
    height: 50vh; /* Abarca toda la altura de la pantalla */
    object-fit: cover; /* Cubre todo el espacio sin deformar */
    pointer-events: none; /* Desactiva la interacción con el video */
    border: none; /* Sin bordes */
}
 
 .overlay-video-content {
     position: absolute;
     top: 40%;
     left: 50%;
     transform: translate(-50%, -50%);
     text-align: center;
     color: black;
     z-index: 2;
     width: 80%; 
     padding: 0 5vw;
 }

 .video-title {
     font-size: 9vw; 
     font-family: 'Yanone Kaffeesatz';
     margin-top: 3vw;
 }


 .bold-text {
     font-weight: bold; /* "MUJEREAL" */
 }

 .regular-text {
     font-weight: 300; /* "TEQUILA" */
 }

 .video-button {
     background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
     color: white;
     font-family: 'Fira Sans Condensed';
     border: none;
     font-weight: 300;
     padding: 3vw 10vw;
     cursor: pointer;
     font-size: 4.5vw; 
     margin-top: 3vw;
     margin-bottom: 15vw;
     transition: color 0.3s ease, background 0.3s ease;
 }

 .video-button:hover {
     background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
     color:#d2d2d2;
     opacity: 90%;
     transition: color 0.3s ease, background 0.3s ease; 
 }

 /* STORY */

 .mujereal-section {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 2vw 5vw;
     margin-top: -50vw;
 }

 .mujereal-container {
     display: flex;
     flex-direction: column; 
     align-items: center;
     width: 100%;
     margin: 0 auto;
 }

 .image-container img {
     width: 100%; 
     height: auto;
     margin-bottom: 5vw;
 }

 .text-container {
     padding-left: 0;
     text-align: center; 
 }

 .text-container h2 {
     font-size: 6.5vw; 
     font-weight: 400;
     color: #333;
     margin-bottom: 3vw;
 }

 .text-container h3 {
     font-size: 4vw;
     color: #333;
     font-weight: 400;
     margin-bottom: 3vw;
 }

 .text-container p {
     font-size: 3.6vw; 
     color: #4d4d4d;
     margin-bottom: 4vw;
     width: 100%; 
     font-weight: 300;
     text-align: justify;
 }

 .cta-button2 {
     background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
     color: white;
     font-family: 'Fira Sans Condensed';
     border: none;
     font-weight: 300;
     padding: 3vw 10vw; 
     cursor: pointer;
     font-size: 3.5vw; 
     margin-top: 4vw; 
 }

 .divider {
     margin-top: 8vw; 
     text-align: center;
 }

 .divider img {
     width: 70vw; 
     height: auto;
 }

 /* PRODUCTS */

 .products-section {
     padding: 8vw 0; 
     background-color: #fdf7f6;
     text-align: center;
 }

 .products-title {
     font-size: 7vw; 
     font-weight: 300;
     margin-bottom: 5vw;
 }

 .products-grid {
     display: grid;
     grid-template-columns: 1fr; 
     gap: 5vw; 
     max-width: 90%; 
     margin: 0 auto;
 }

 .product-item {
     text-align: center;
 }

 .product-image {
     width: 100%;
 }

 .product-info {
     flex-direction: column; 
     align-items: center;
     padding-top: 3vw;
 }

 .product-name {
     font-size: 7vw; 
     color: #333;
     font-weight: 500;
     margin-bottom: 3vw;
 }

 .more-info-button {
     padding: 3vw 5vw; 
     font-size: 3.5vw; 
 }


  .product-item:hover .overlay,
  .product-item:hover .product-name,
  .product-item:hover .more-info-button {
      opacity: 1;
      color: #edac9f;
      transition: color 0.3s ease, background 0.3s ease;
  }

  .product-item:hover .more-info-button {
      background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
      color: #fff;
  }

  /*  CONTACT  */

  .contact-section {
     margin-top: 15vw;
     padding: 5vw;
     
 }

 .contact-container {
     flex-direction: column; 
     align-items: center;
     text-align: center;
 }

 .contact-image {
     margin-right: 0; 
     margin-bottom: 5vw;
 }

 .contact-image img {
     width: 80vw; 
     height: auto;
 }

 .contact-form h2 {
     font-size: 7vw;
     margin-bottom: 2vw;
 }

 .contact-title {
     font-size: 7vw;
     margin-bottom: 3vw;
     text-align: left;
 }

 .contact-subtitle {
     font-size: 4vw;
    
 }

 .divider img {
     width: 70vw; 
     margin-bottom: 3vw;
 }

 .contact-form input,
 .contact-form textarea {
     margin-left: 5vw;
     font-size: 4vw;
     padding: 3vw;
     width: 80vw; 
     margin-bottom: 3vw;
     text-align: center;
 }

 .contact-form textarea {
     min-height: 20vw; 
 }

 .contact-button {
     width: 50vw; 
     font-size: 4vw;
     padding: 3vw 5vw;
     margin-left: 20vw;
 }

 .contact-req {
     font-size: 3.5vw;
     margin-top: 5vw;
 }


 /*  STORY SECTION */

 .story-section {
     padding: 5vw 0;
     text-align: center;
     background-color: #fff;
     margin-top: -120vw;
 }

 .story-container {
     width: 90%; 
     margin: 0 auto;
 }

 .story-title {
     font-family: 'Yanone Kaffeesatz';
     font-size: 6vw; 
     font-weight: 300;
     color: #333;
     margin-bottom: 3vw;
 }

 .story-subtitle {
     font-family: 'Yanone Kaffeesatz';
     font-size: 7vw; 
     font-weight: 400;
     color: #333;
     margin-bottom: 5vw;
 }

 .story-content {
     text-align: center;
 }

 .story-image {
     margin: 0 auto;
 }

 .story-image img {
     width: 100%;
     height: auto;
 }

 .story-text {
     margin-top: 5vw;
 }

 .story-text h4 {
     font-family: 'Fira Sans Condensed';
     font-size: 4vw; 
     font-weight: 400;
     color: #333333;
     text-align: justify;
     margin-bottom: 2vw;
 }

 .story-text p {
     font-family: 'Roboto';
     font-size: 3.5vw; 
     color: #4d4d4d;
     font-weight: 300;
     text-align: justify;
     line-height: 1.6;
     margin-bottom: 5vw;
 }

 .story-divider {
     margin-top: 5vw;
     text-align: center;
 }

 .story-divider img {
     width: 70vw; 
     height: auto;
 }


 /* STORY 2 */

 .heritage-section {
     padding: 10vw 0;
     background-color: #fff;
     text-align: center;
     margin-top: 5vw;
 }

 .heritage-container {
     width: 90%; 
     margin: 0 auto;
 }

 .heritage-block {
     display: flex;
     flex-direction: column; 
     align-items: center;
     margin-bottom: 5vw;
 }

 .heritage-image {
     width: 100%;
     padding: 2vw;
     text-align: center;
 }

 .heritage-image img {
     width: 100%;
     height: auto;
 }

 .heritage-content {
     width: 100%;
     padding: 2vw;
     text-align: justify;
 }

 .heritage-content h4 {
     font-family: 'Yanone Kaffeesatz';
     font-size: 5vw;  
     font-weight: 400;
     color: #333;
     margin-bottom: 2vw;
 }

 .heritage-content p {
     font-family: 'Roboto';
     font-size: 3.5vw;  
     color: #4d4d4d;
     font-weight: 300;
     line-height: 1.6;
 }

 .heritage-divider {
     margin: 5vw 0;
     text-align: center;
 }

 .heritage-divider img {
     width: 50vw;  
     height: auto;
 }


 /* FOOTER */

 .footer {
     text-align: center;
     padding: 5vw;
     font-family: 'Fira Sans Condensed';
 }

 .footer-contact {
     display: flex;
     flex-direction: column; 
     align-items: center;
     gap: 5vw;
     font-size: 4.5vw;
     color: #edac9f;
 }

 .footer-socials a {
     margin: 0 8px;
     color: #edac9f;
     font-size: 4vw;
 }

 .footer-divider img {
     width: 70vw;
     margin-top: 5vw;
 }

 .footer-bottom {
     display: flex;
     flex-direction: column; 
     align-items: center;
     font-size: 4vw;
     border-top: 1vw;
     padding-top: 3vw;
     text-align: center;
 }

 .footer-arrow img {
     width: 10vw; 
 }

 .footer-bottom p {
     color: #b2b2b2;
     font-family: 'Roboto';
     font-size: 3vw;
     margin: 5vw 0;
 }

 .footer-bottom a {
     font-size: 3vw;
     color: #b2b2b2;
     text-decoration: none;
     font-family: 'Roboto';
 }

 .footer-developed img {
     margin-left: 2vw;
     width: 30vw;
 }

 /* PRODUCTS SECTION */

 .mujereal-section2 {
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #fdf7f6;
     padding-bottom: 0;
 }

 .mujereal-container2 {
     display: flex;
     flex-direction: column; 
     align-items: center;
     width: 100%; 
     margin-top: 21vw;
 }

 .image-container2 img {
     width: 100%;
     height: auto;
     margin-bottom: 5vw;
     clip-path: polygon(
    10vw 0,
    calc(100% - 10vw) 0,
    calc(100% - 10vw) calc(100% - 8vw),
    10vw calc(100% - 8vw)
  );
 }

 .text-container2 h2 {
     font-family: 'Yanone Kaffeesatz';
     font-size: 13vw;  
     font-weight: 500;
     color: #333;
     margin-bottom: 2vw;
     text-align: center;
 }

 .text-container2 h4 {
     font-family: 'Yanone Kaffeesatz';
     font-size: 6vw;  
     font-weight: 300;
     color: #333;
     margin-bottom: 2vw;
     text-align: center;
 }

 .text-container2 h3 {
     font-family: 'Roboto';
     font-size: 4vw;  
     color: #4d4d4d;
     font-weight: 600;
     margin-bottom: 5vw;
     text-align: center;
 }

 .heritage-divider2 {
     margin: 2vw 0;
     text-align: center;
 }
 
 .heritage-divider2 img {
     width: 70vw;
     height: auto;
 }

 .text-container2 p {
     font-family: 'Roboto';
     font-size: 3.7vw;  
     color: #4d4d4d;
     margin-bottom: 5vw;
     text-align: center;
     margin-left: 2vw;
     width: 80vw;
     font-weight: 300;
 }



 .detail {
     margin-top: 5vw;
 }

 .detail-item {
     display: flex;
     flex-direction: column; 
     align-items: center;
     margin-bottom: 3vw;
 }

 .detail-icon {
     width: 10vw; 
     margin-bottom: 3vw;
     margin-left: 3vw;
 }

 .detail-icon2 {
     width: 8vw; 
      margin-bottom: 3vw;
      margin-left: 3vw;
 }

 .detail p {
     font-family: 'Roboto';
     font-size: 3.8vw;  
     color: #4d4d4d;
     font-weight: 300;
     width: 80vw;
 }

 .text-container2 strong{
     font-size: 5vw;
     font-family: 'Yanone Kaffeesatz';
     font-weight: 400;
 }
 

 .cta-button3 {
     background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
     color: white;
     font-family: 'Fira Sans Condensed';
     border: none;
     font-weight: 300;
     padding: 3vw 10vw;
     cursor: pointer;
     font-size: 4vw;
     margin-top: 5vw;
     margin-left: 25vw;
     margin-bottom: 20vw;
     transition: color 0.3s ease, background 0.3s ease;
 }



 .instagram-title h2 {
     font-size: 4vw;
     margin-bottom: 2vw;
     margin-top: 6vw;
 }
 
 .instagram-icon {
     width: 4vw; 
     margin-top: 6vw;
     margin-bottom: 2vw;
 }

 /* Estilos generales para la sección */
.instagram-section {
 display: flex;
 flex-direction: column;
 align-items: center;
 padding: 10px;
 margin-top: -90vw;
}

.instagram-title {
 display: flex;
 align-items: center;
 gap: 10px;
 margin-bottom: 20px;
}

.instagram-title h2 {
 font-size:2em;
 font-weight: bold;
}

.instagram-icon {
 width: 24px;
 height: 24px;
}

/* Contenedor del carrusel */
.carousel-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

/* Contenedor de publicaciones */
.instagram-container {
    display: flex;
    overflow-x: hidden; /* Oculta el scroll visual */
    scroll-snap-type: x mandatory; /* Anclaje al desplazarse */
    scroll-behavior: smooth; /* Desplazamiento suave */
    gap: 16px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box; /* Incluye el padding */
}

/* Ocultar la barra de scroll */
.instagram-container::-webkit-scrollbar {
    display: none;
}

/* Publicaciones individuales */
.instagram-post {
    scroll-snap-align: center; /* Anclar al centro */
    flex: 0 0 70%; /* Ocupa el 80% del contenedor */
    height: auto;
}

/* Imágenes y videos dentro de publicaciones */
.instagram-post img,
.instagram-post video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Botones de navegación */
.prev-btn,
.next-btn {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.2em;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

.carousel-inner img,
.carousel-inner video {
    width: 100%;
    height: auto; /* Mantiene la proporción original */
    max-height: 450px; /* Altura máxima, ajusta según tu diseño */
    object-fit: cover; /* Corta el contenido si es necesario para llenar el espacio */
}

.carousel-instagram{
    width: 100%;
    height: auto;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
opacity: 0;
}


/* Estilo para el contenedor del botón móvil */
.buy-now-mobile {
    position: absolute;
    top: 50%;
    right: 20px; /* Ajusta la distancia del borde derecho */
    transform: translateY(-50%); /* Centra verticalmente con respecto al navbar */
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

/* Estilo para el botón */
.cta-mobile-button {
    padding: 1vw 2vw;
    font-size: 3vw;
    background: linear-gradient(90deg, #a9715c, #edac9f, #c2816d);
    color: white;
    border: none;
    font-family: 'Fira Sans Condensed';
    font-weight: 300;
    cursor: pointer;
}

.cta-mobile-button:hover {
    background: linear-gradient(90deg, #8a5a4a, #d69a82, #a76c5d);
    color: #d2d2d2;
    transition: background 0.3s ease, color 0.3s ease;
}




   }